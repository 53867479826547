
export const LOGIN = "/login";
export const HOME = "/";
export const NYUUKA = "/nyuuka";
export const NYUUKA_DETAIL = "/nyuuka/detail";
export const SHUKKA = "/shukka";
export const SHUKKA_DETAIL = "/shukka/detail";
export const ZAIKO_JAN_LIST = "/zaiko";
export const ZAIKO_EPC_LIST = "/zaiko/epc";
export const ZAIKO_EPC_EVENT_LIST = "/zaiko/epc/event";
export const TANAOROSHI = "/tanaoroshi";
export const TANAOROSHI_SAI = "/tanaoroshi/sai";
export const TANAOROSHI_ADJUSTMENT_HISTORY = "/tanaoroshi/adjustment/history";
export const USER = "/user";
export const DASHBOARD = "/dashboard";
export const ZAIKO_MOVE = "/zaiko-move";
export const ZAIKO_MOVE_DETAIL = "/zaiko-move/detail";
export const SALES = "/sales";

export const Paths = {
    LOGIN,
    HOME,
    NYUUKA,
    NYUUKA_DETAIL,
    SHUKKA,
    SHUKKA_DETAIL,
    ZAIKO_JAN_LIST,
    ZAIKO_EPC_LIST,
    ZAIKO_EPC_EVENT_LIST,
    TANAOROSHI,
    TANAOROSHI_SAI,
    TANAOROSHI_ADJUSTMENT_HISTORY,
    USER,
    DASHBOARD,
    ZAIKO_MOVE,
    ZAIKO_MOVE_DETAIL,
    SALES,
}

export default Paths;
