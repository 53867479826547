import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import vuetify from "@/plugins/vuetify";

import { TableDefinitionManager } from "@/plugins/tableDefinitionManager";
import { i18n } from "@/plugins/i18n";

const app = createApp(App);
//app.config.compilerOptions.isCustomElement = tag => tag.startsWith('Datepicker')
app.use(i18n)
    .use(router)
    .use(vuetify)
    .use(TableDefinitionManager)
    .mount('#app');

    