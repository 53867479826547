<template>
  <v-dialog v-model="dialog" width="800" @click:outside="cancel" :persistent="isLoading">
    <v-card>
      <v-card-title>
        <h3><strong>{{ $t('tanaoroshiAddJanDialog.label.title') }}</strong></h3>
      </v-card-title>
      <v-container class="pa-8" fluid>
        <!-- 商品検索フォーム -->
        <v-row>
          <v-col cols="4">
            <v-text-field density="compact" variant="outlined" hide-details="auto"
              v-model="searchProductCondition.janCode" :label="$t('tanaoroshiAddJanDialog.label.janCode')" />
          </v-col>
          <v-col cols="4">
            <v-text-field density="compact" variant="outlined" hide-details="auto"
              v-model="searchProductCondition.productNumber"
              :label="$t('tanaoroshiAddJanDialog.label.productNumber')" />
          </v-col>
          <v-col cols="4" class="text-right">
            <v-btn text @click="clear">{{ $t('common.action.clear') }}</v-btn>
            <v-btn theme="dark" class="ml-4 px-8 bg-grey-darken-3" elevation="2" @click.prevent="search"
              :disabled="!canSearchProduct" :loading="isSearching">{{ $t('common.action.search') }}</v-btn>
          </v-col>
        </v-row>
        <!-- 商品検索結果一覧 -->
        <v-row>
          <v-col cols="12">
            <v-table density="compact" class="text-no-wrap">
              <thead>
                <tr>
                  <th></th>
                  <th v-for="header in headers" :key="header" @click.stop="tableSort(header)"
                    :style="cursorCheck(header)">
                    {{ $t("tanaoroshiAddJanDialog.label.table." + header) }}</th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="data in filteredDataList" :key="data.no">
                  <td>
                    <input type="radio" v-model="registerInfo.janCode" :value="data['janCode']" />
                  </td>
                  <template v-for="header in headers" :key="header">
                    <td>{{ data[header] }}</td>
                  </template>
                </tr>
                <tr>
                  <td :colspan="headers.length">
                  </td>
                </tr>
              </tbody>
            </v-table>
          </v-col>
        </v-row>
        <!-- 追加情報入力フォーム -->
        <v-form v-model="isFormValid" ref="form">
          <v-row>
            <v-col cols="12">
              <v-select v-model="registerInfo.htSerial" :items="htSerialItem"
                :label="$t('tanaoroshiAddJanDialog.label.htSerial')" :rules="[rules.required]" density="compact"
                hide-details="auto" :multiple="false" item-value="code" item-title="code"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-select v-model="registerInfo.zoneCode" :items="zoneItem"
                :label="$t('tanaoroshiAddJanDialog.label.zoneCode')" :rules="[rules.required]" density="compact"
                hide-details="auto" :multiple="false" item-value="code" item-title="code_name"></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field density="compact" variant="outlined" hide-details="auto" class="pt-0 mt-0"
                v-model="registerInfo.inventoryQuantity" :rules="[rules.required]"
                :label="$t('tanaoroshiAddJanDialog.label.inventoryQuantity')"
                @input="fixInventoryQuantity($event)"></v-text-field>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <v-text-field density="compact" variant="outlined" hide-details="auto" v-model="registerInfo.staffId"
                :label="$t('tanaoroshiAddJanDialog.label.staffId')" :counter="staffIdLength" :maxLength="staffIdLength"
                :disabled="isLoading" :rules="[rules.required, rules.checkStaffId]" />
            </v-col>
          </v-row>
        </v-form>
        <ZeroData v-if="zeroDataWarningDialogFlg" :handle-ok="closeZeroDataDialog" />
      </v-container>
      <span class="ml-4" v-if="errorMessage" style="color: red"> {{ errorMessage }}</span>
      <div class="ml-4" v-for="data in errorList" :key="data.message" style="color: red">{{ data.message }}</div>
      <v-divider></v-divider>
      <v-card-actions class="pa-4">
        <v-spacer></v-spacer>
        <v-btn text @click="cancel" :disabled="isLoading"> {{ $t('common.action.cancel') }} </v-btn>
        <v-btn color="indigo darken-1" dark @click="ok" :loading="isLoading" :disabled="!isValidInput">
          {{ $t('common.action.ok') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import api from "@/apis/staff";
import constant from "@/utils/constant"
import ZeroData from "@/components/dialog/ZeroDataDialog";

export default {
  components: { ZeroData },
  props: {
    dialogData: {
      type: Object,
      required: true,
    },
    handleOk: {
      type: Function,
      required: true,
    },
    handleCancel: {
      type: Function,
      required: true,
    },
  },
  computed: {
    isValidInput() {
      return this.isFormValid && this.registerInfo.janCode;
    },
    headers() {
      return this.$getTableDef("TanaoroshiAddJanDialog");
    },
    sortedTable() {
      let tempArray = this.productList.map(x => x);
      if (this.sortState.target != "") {
        return tempArray.sort(this.defaultSorter(this.sortState.ascent));
      }
      return tempArray;
    },
    filteredDataList() {
      const tempArray = this.sortedTable;
      return tempArray.slice();
    },
    canSearchProduct() {
      return this.searchProductCondition.janCode || this.searchProductCondition.productNumber;
    },
    htSerialItem() {
      let item = [];
      for (let key of Object.keys(this.dialogData.htSerialZoneMap)) {
        item.push({ code: key });
      }
      return item;
    },
    zoneItem() {
      let item = [];
      for (let obj of this.dialogData.htSerialZoneMap[this.registerInfo.htSerial] ?? []) {
        item.push({
          code: obj.zoneCode,
          code_name: `${obj.zoneName} (${obj.siteCode}_${obj.storeName})`
        });
      }
      return item;
    },
  },
  data() {
    return {
      isFormValid: true,
      dialog: true,
      isLoading: false,
      staffIdLength: 0,
      rules: {
        required: v => {
          return this.hasLength(v) || this.$t('userRegisterDialog.validation.required');
        },
        checkStaffId: v => {
          return this.isValidStaffId(v) || this.$t('tanaoroshiAddJanDialog.validation.halfAlnum');
        },
      },
      registerInfo: {
        janCode: null,
        htSerial: null,
        zoneCode: null,
        inventoryQuantity: null,
        staffId: null,
      },
      // 商品検索関連
      sortState: { target: "", ascent: true },
      productList: [],
      zeroDataWarningDialogFlg: false,
      searchProductCondition: {
        janCode: null,
        productNumber: null
      },
      // エラー出力
      errorMessage: "",
      errorList: [],
    };
  },
  mounted() {
    this.staffIdLength = constant.STAFF_CODE_MAX_LENGTH;

    const keys = Object.keys(this.dialogData.htSerialZoneMap);
    if (keys.length != 0) {
      this.registerInfo.htSerial = keys[0];
    }
  },
  methods: {
    hasLength(v) {
      return !(v == null || v == undefined || v == "");
    },
    fixInventoryQuantity(event) {
      const val = event.target.value;
      this.registerInfo.inventoryQuantity = val.replace(/[^0-9]/g, '');
    },
    isValidStaffId(v) {
      return /^[0-9A-Za-z]*$/.test(v);
    },
    cancel() {
      if (this.isLoading) return;
      this.handleCancel();
    },
    ok() {
      this.isLoading = true;
      api.post("/tanaoroshi/adjustment/add-jan", {
        tanaoroshiId: this.dialogData.tanaoroshiId,
        janCode: this.registerInfo.janCode,
        staffId: this.registerInfo.staffId,
        inventoryQuantity: this.registerInfo.inventoryQuantity,
        htSerial: this.registerInfo.htSerial,
        zoneCode: this.registerInfo.zoneCode,
        searchDatetime: this.dialogData.searchDatetime,
      })
        .then((response) => {
          if (response.data?.status == 0) {
            this.errorMessage = "";
            this.handleOk();
          } else {
            this.errorMessage = response.data?.errorMessage;
            this.errorList = response.data?.errorList;
          }
        })
        .catch(() => {
          // do nothing
        })
        .finally(() => {
          this.isLoading = false;
        })
    },
    // 商品検索関連 =======================================================================
    cursorCheck() {
      return "cursor: pointer;"
    },
    tableSort(headValue) {
      if (this.sortState.target == headValue) {
        this.sortState.ascent = !this.sortState.ascent;
      }
      else {
        this.sortState.target = headValue;
        this.sortState.ascent = true;
      }
    },
    defaultSorter(isAscent) {
      return (a, b) => {
        const multiplier = isAscent ? 1 : -1;
        if (a[this.sortState.target] > b[this.sortState.target]) return 1 * multiplier;
        if (a[this.sortState.target] < b[this.sortState.target]) return -1 * multiplier;
        return 0;
      }
    },
    clear() {
      this.searchProductCondition = {
        janCode: "",
        productNumber: ""
      }
    },
    closeZeroDataDialog() {
      this.zeroDataWarningDialogFlg = false;
    },
    resetCondition() {
      this.sortState.target = "";
      this.sortState.ascent = true;
    },
    search() {
      this.isSearching = true;
      api.post("/product/search", {
        janCode: this.searchProductCondition.janCode,
        productCode: this.searchProductCondition.productNumber,
      }).then((response) => {
        this.registerInfo.janCode = null;
        this.productList = response.data.results?.productList ?? [];
        this.resetCondition();
        this.zeroDataWarningDialogFlg = this.productList.length == 0;
      }).catch(() => {
        this.productList = [];
        this.zeroDataWarningDialogFlg = true;
      }).finally(() => {
        this.isSearching = false;
      });
    },
  },
};
</script>
<style>
.pre-line {
  white-space: pre-line;
}
</style>
